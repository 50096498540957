// extracted by mini-css-extract-plugin
export var root = "Technology-module--root--7b53c";
export var background = "Technology-module--background--3fd69";
export var title = "Technology-module--title--e4fd6";
export var subtitle = "Technology-module--subtitle--317f1";
export var container = "Technology-module--container--fd1a2";
export var before = "Technology-module--before--110d8";
export var after = "Technology-module--after--e6bcb";
export var beforeBlock = "Technology-module--beforeBlock--50691";
export var afterBlock = "Technology-module--afterBlock--0d0ed";
export var bg = "Technology-module--bg--d74e1";
export var beforeCard = "Technology-module--beforeCard--070f1";
export var afterCard = "Technology-module--afterCard--ec1df";
export var icon = "Technology-module--icon--5fd93";
export var km = "Technology-module--km--ee133";
export var small = "Technology-module--small--a4e7a";
export var orange = "Technology-module--orange--cbdec";
export var green = "Technology-module--green--fa1fa";