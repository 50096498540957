// extracted by mini-css-extract-plugin
export var block = "Patents-module--block--ebc6b";
export var lightBorder = "Patents-module--lightBorder--5d320";
export var disable = "Patents-module--disable--047a7";
export var title = "Patents-module--title--9d93a";
export var subtitle = "Patents-module--subtitle--42c33";
export var hexagon = "Patents-module--hexagon--11df7";
export var shieldCheck = "Patents-module--shieldCheck--eb968";
export var modal = "Patents-module--modal--d1018";
export var header = "Patents-module--header--3c6f1";
export var flag = "Patents-module--flag--0be7f";
export var button = "Patents-module--button--d2f4e";