import React from 'react'
import { getTranslate } from 'react-localize-alias'

import * as styles from './Card.module.scss'

import { Button, Link, LinkProps, Wave } from '@UIKit'

interface CardProps {
  icon: React.ReactNode
  title: string
  description: string
  to: LinkProps['to']
  disableButton?: boolean
  disableAnimation?: boolean
  animationDelay?: string
}

export const Card: React.FC<CardProps> = ({
  icon,
  title,
  description,
  to,
  disableButton,
  disableAnimation,
  animationDelay,
}) => {
  const content = (
    <>
      <div className={styles.icon}>{icon}</div>

      <div className={styles.text}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>

      {!disableButton && (
        <Button className={styles.button} fullWidth>
          {getTranslate('project.card.button')}
        </Button>
      )}
    </>
  )

  return (
    <Link to={to} allowEn>
      <div className={styles.border}>
        {disableAnimation ? (
          <div className={styles.root}>{content}</div>
        ) : (
          <Wave className={styles.root} animationDelay={animationDelay}>
            {content}
          </Wave>
        )}
      </div>
    </Link>
  )
}
