import React from 'react'

import cn from 'classnames'

import * as styles from './Wrapper.module.scss'

interface WrapperProps {
  maxWidth?: '1776' | '820',
  className?: string,
}

export const Wrapper: React.FC<WrapperProps> = ({ maxWidth = '1776', className, children }) => (
  <div className={cn(styles.root, styles[`maxWidth${maxWidth}`], className)}>
    {children}
  </div>
)
