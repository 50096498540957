// extracted by mini-css-extract-plugin
export var root = "Goal-module--root--2856b";
export var hide = "Goal-module--hide--9f1df";
export var content = "Goal-module--content--98011";
export var blur = "Goal-module--blur--d365a";
export var title = "Goal-module--title--0cb5e";
export var subtitle = "Goal-module--subtitle--44b69";
export var roadMap = "Goal-module--roadMap--52ca9";
export var grid = "Goal-module--grid--842b9";
export var step = "Goal-module--step--74e31";
export var link = "Goal-module--link--b1506";
export var animated = "Goal-module--animated--9a4f7";
export var blink = "Goal-module--blink--c775e";
export var icon = "Goal-module--icon--1fb8a";