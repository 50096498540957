import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { getTranslate } from 'react-localize-alias'

import * as styles from './StockCharts.module.scss'

import { Tabs, Typography } from '@UIKit'

interface StockChartsProps {
  name: string
  title: string
}

export const StockCharts: React.FC<StockChartsProps> = ({ name, title }) => {
  const tabs = [
    {
      tab: 'Google',
      content: (
        <div className={styles.content}>
          <Typography color={'default'} className={styles.title} size={64} weight={500} disableLocalize>
            Google
          </Typography>
          <Typography color={'default'} className={styles.text} size={48} weight={500}>
            landing.stock.chart.title
          </Typography>
          <StaticImage
            loading="eager"
            placeholder="blurred"
            src="../../../../../assets/images/weter/googleChart.svg"
            alt="Google stock chart"
          />
        </div>
      ),
    },
    {
      tab: 'Tesla',
      content: (
        <div className={styles.content}>
          <Typography color={'default'} className={styles.title} size={64} weight={500} disableLocalize>
            Tesla
          </Typography>
          <Typography color={'default'} className={styles.text} size={48} weight={500}>
            landing.stock.chart.title
          </Typography>
          <StaticImage
            loading="eager"
            placeholder="blurred"
            src="../../../../../assets/images/weter/teslaChart.svg"
            alt="Tesla stock chart"
          />
        </div>
      ),
    },
    {
      tab: 'Apple',
      content: (
        <div className={styles.content}>
          <Typography color={'default'} className={styles.title} size={64} weight={500} disableLocalize>
            Apple
          </Typography>
          <Typography color={'default'} className={styles.text} size={48} weight={500}>
            landing.stock.chart.title
          </Typography>
          <StaticImage
            loading="eager"
            placeholder="blurred"
            src="../../../../../assets/images/weter/appleChart.svg"
            alt="Apple stock chart"
          />
        </div>
      ),
    },
    {
      tab: getTranslate(name, { missOnAlias: true }),
      content: (
        <div className={styles.content}>
          <Typography color={'default'} className={styles.title} size={64} weight={500} missOnAlias>
            {title}
          </Typography>
          <Typography color={'default'} className={styles.text} size={48} weight={500}>
            landing.stock.chart.title
          </Typography>
          <StaticImage
            loading="eager"
            placeholder="blurred"
            src="../../../../../assets/images/weter/weterChart.svg"
            alt="Apple stock chart"
          />
        </div>
      ),
    },
  ]

  return (
    <section className={styles.root}>
      <Tabs autoplay tabs={tabs} />
    </section>
  )
}
