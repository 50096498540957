import React from 'react'

import { Wrapper } from '@components/App/Wrapper'

import * as styles from './Important.module.scss'
import { ImportantBlock } from './ImportantBlock'
import { Typography } from '@UIKit'
import { StaticImage } from 'gatsby-plugin-image'

const badIcon = (
  <StaticImage
    placeholder="blurred"
    src="../../../../../../assets/images/weter/X.png"
    alt="bad"
    height={231}
    width={231}
  />
)
const goodIcon = (
  <StaticImage
    placeholder="blurred"
    src="../../../../../../assets/images/weter/V.png"
    alt="bad"
    height={231}
    width={231}
  />
)

export const Important: React.FC = ({ cons, pros }) => (
  <section id="Perks">
    <Wrapper>
      <div className={styles.root}>
        <Typography className={styles.title} size={64} weight={500}>
          important.title
        </Typography>

        <div className={styles.reasons}>
          <ImportantBlock iconImage={badIcon} key={cons.title} {...cons} />
          <ImportantBlock iconImage={goodIcon} key={pros.title} {...pros} />
        </div>
      </div>
    </Wrapper>
  </section>
)
