import React from 'react'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { Flag, HexagonImage } from '@UIKit'

import * as styles from './Face.module.scss'

interface FaceProps {
  name: string
  image: IGatsbyImageData
  country: string
}

export const Face: React.FC<FaceProps> = ({ name, image, country }) => (
  <>
    <HexagonImage className={styles.hexagon} withoutborder>
      <GatsbyImage image={image} alt={name} />
    </HexagonImage>
    <Flag className={styles.flag} country={country} />
  </>
)
