/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

import { getApi } from '../../../../../../../api/_utils'

import { Button, Flag, Pagination, Select, Typography } from '@UIKit'
import { CommitteeMember } from '@graphql'

import { Person } from '../Person'

import * as styles from './CommitteeSelect.module.scss'

const PAGE_SIZE = 7
const DEFAULT_OPTION = 'weter.committee.select.default'

interface CommitteeSelectProps {
  countries: string[]
  committee: CommitteeMember[]
}

export const CommitteeSelect: React.FC<CommitteeSelectProps> = ({ countries, committee }) => {
  const [options, setOptions] = useState([
    { value: DEFAULT_OPTION },
    ...countries.map((countryCode) => ({ value: countryCode })),
  ])

  const [selected, setSelected] = useState(options[0].value)
  const [page, setPage] = useState(1)

  const selectedCommitteeMembers = committee.filter(({ country }) => selected === country)
  const totalPages = Math.ceil(selectedCommitteeMembers.length / PAGE_SIZE)

  const handleSelect = (id: string | number) => {
    setSelected(String(id))
    setPage(1)
  }

  useEffect(() => {
    const selectUserCountry = async () => {
      const userCountry: string = await fetch(getApi('application/defaults/user'))
        .then((data) => data.json())
        .then(({ data }) => data.country.toLowerCase())

      if (countries.includes(userCountry)) {
        setOptions([
          { value: DEFAULT_OPTION },
          { value: userCountry },
          ...countries.filter((country) => country !== userCountry).map((countryCode) => ({ value: countryCode })),
        ])
      }
    }

    selectUserCountry()
  }, [])

  return (
    <div className={styles.root}>
      <div className={styles.actions}>
        <Select
          selected={selected}
          options={options}
          setSelected={handleSelect}
          // eslint-disable-next-line react/no-unstable-nested-components
          renderOption={({ value: countryCode }) => (
            <span key={countryCode} className={styles.option}>
              <Flag className={styles.flag} country={String(countryCode)} />
              <Typography size={18} missOnAlias>
                {String(countryCode).length === 2 ? `country.${countryCode}` : countryCode}
              </Typography>
            </span>
          )}
        />
      </div>
      <div className={styles.list}>
        {selected !== DEFAULT_OPTION && (
          <>
            {selectedCommitteeMembers.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE).map((member) => (
              <Person key={member.name} {...member} />
            ))}
            {selectedCommitteeMembers.length > 5 && (
              <div className={styles.pagination}>
                <Pagination activePage={page} totalPage={totalPages} onChange={setPage} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
