/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import { IGatsbyImageData } from 'gatsby-plugin-image'

import { Hexagon, Link, Typography } from '@UIKit'

import FacebookIcon from '../../../../../../assets/images/weter/facebookIcon.inline.svg'
import InstagramIcon from '../../../../../../assets/images/weter/instagramIcon.inline.svg'
import TelegramIcon from '../../../../../../assets/images/weter/telegramIcon.inline.svg'
import TwitterIcon from '../../../../../../assets/images/weter/twitterIcon.inline.svg'
import VkIcon from '../../../../../../assets/images/weter/vkIcon.inline.svg'
import LinkedinIcon from '../../../../../../assets/images/weter/linkedinIcon.inline.svg'

import * as styles from './Person.module.scss'

import { Face } from '../Face'
import { CommitteeMember } from '@graphql'

const socialMediaIcons = {
  facebook: <FacebookIcon className={styles.icon} />,
  instagram: <InstagramIcon className={styles.icon} />,
  telegram: <TelegramIcon className={styles.icon} />,
  twitter: <TwitterIcon className={styles.icon} />,
  vk: <VkIcon className={styles.icon} />,
  linkedin: <LinkedinIcon className={styles.icon} />,
}

interface PersonProps {
  name: string
  country: string
  image: IGatsbyImageData
  socialMedia: CommitteeMember['socialMedia']
}

export const Person: React.FC<PersonProps> = ({ name, country, image, socialMedia }) => (
  <Link className={styles.root} to={socialMedia?.[0].url} target="_blank">
    <div className={styles.avatar}>
      <Face name={name} image={image} country={country} />
    </div>
    <div className={styles.info}>
      <Typography size={20} weight={700} color="white" disableLocalize>
        {name}
      </Typography>
      <Typography size={18} missOnAlias>{`country.${country}`}</Typography>
    </div>
    <div className={styles.links}>
      {socialMedia?.map(({ type, url }) => (
        <div key={type} className={styles.hexagon}>
          <Link className={styles.link} to={url} target="_blank">
            <Hexagon size={52} color="dark" cursor="pointer">
              {socialMediaIcons[type as keyof typeof socialMediaIcons]}
            </Hexagon>
          </Link>
        </div>
      ))}
    </div>
  </Link>
)
