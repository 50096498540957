// extracted by mini-css-extract-plugin
export var root = "OldSolutions-module--root--01832";
export var title = "OldSolutions-module--title--8e679";
export var vs = "OldSolutions-module--vs--05765";
export var subtitle = "OldSolutions-module--subtitle--c0723";
export var reasons = "OldSolutions-module--reasons--7584d";
export var background = "OldSolutions-module--background--5e68d";
export var point = "OldSolutions-module--point--c27fb";
export var ml = "OldSolutions-module--ml--51b3f";
export var mr = "OldSolutions-module--mr--d668f";
export var container = "OldSolutions-module--container--1d964";
export var reason = "OldSolutions-module--reason--18fc1";
export var image = "OldSolutions-module--image--f480b";
export var right = "OldSolutions-module--right--76005";
export var left = "OldSolutions-module--left--9a8a7";
export var icon = "OldSolutions-module--icon--28611";