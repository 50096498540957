// extracted by mini-css-extract-plugin
export var root = "Benefit-module--root--38086";
export var title = "Benefit-module--title--4e599";
export var container = "Benefit-module--container--78233";
export var carousel = "Benefit-module--carousel--bae5a";
export var top = "Benefit-module--top--2b81c";
export var bottom = "Benefit-module--bottom--6dbc2";
export var pinkLine = "Benefit-module--pinkLine--bc044";
export var yellowLine = "Benefit-module--yellowLine--0d60b";
export var blueLine = "Benefit-module--blueLine--8cec9";
export var greenLine = "Benefit-module--greenLine--f10e4";
export var ground = "Benefit-module--ground--ac6f3";
export var icon = "Benefit-module--icon--4c718";
export var info = "Benefit-module--info--38c23";
export var frame = "Benefit-module--frame--c411f";
export var pink = "Benefit-module--pink--447e5";
export var yellow = "Benefit-module--yellow--bfff3";
export var blue = "Benefit-module--blue--95fc3";
export var green = "Benefit-module--green--9c89f";
export var crop = "Benefit-module--crop--d9624";
export var bg = "Benefit-module--bg--259ba";