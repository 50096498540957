/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'

import LikesIcon from '../../../../../../assets/images/weter/likesIcon.inline.svg'
import ViewsIcon from '../../../../../../assets/images/weter/viewsIcon.inline.svg'
import ArrowIcon from '../../../../../../assets/images/weter/arrowIcon.inline.svg'

import { Link, Badge, Typography } from '@UIKit'

import * as styles from './CardNews.module.scss'
import dayjs from 'dayjs'

interface CardNewsProps {
  date: string
  image: IGatsbyImageData
  title: string
  description: string
  views: number
  likes: number
}

export const CardNews: React.FC<CardNewsProps> = ({ date, image, title, description, views, likes }) => (
  <div className={styles.root}>
    <div className={styles.cover}>
      {image ? (
        <GatsbyImage className={styles.img} image={image} alt="" />
      ) : (
        <StaticImage
          className={styles.img}
          src="../../../../../assets/images/weter/newsPoster.jpg"
          alt="WETER LOGO"
          placeholder="blurred"
          aspectRatio={1.77} // 16 / 9
        />
      )}
      <Badge className={styles.date}>
        <Typography size={18} color="white" disableLocalize>
          {dayjs(date).format('DD MMM YYYY')}
        </Typography>
      </Badge>
    </div>
    <div className={styles.text}>
      <Typography className={styles.title} size={24} weight={700} disableLocalize>
        {title}
      </Typography>
      <Typography className={styles.description} size={20} disableLocalize>
        {description}
      </Typography>
    </div>
    <div className={styles.info}>
      <div className={styles.indicators}>
        <ViewsIcon className={styles.icon} />
        <Typography size={20} color="white" disableLocalize>
          {views}
        </Typography>
      </div>
      <div className={styles.indicators}>
        <LikesIcon className={styles.icon} />
        <Typography size={20} color="white" disableLocalize>
          {likes}
        </Typography>
      </div>
      <div className={styles.link}>
        <Typography size={20} color="inherit">
          landing.news.read
        </Typography>
        <ArrowIcon className={styles.icon} width={16} height={16} />
      </div>
    </div>
  </div>
)
