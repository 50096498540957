/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'

import * as styles from './Main.module.scss'

import { useInterval } from '@hooks'
import { StaticImage } from 'gatsby-plugin-image'
import { Hexagon, Typography } from '@UIKit'
import PlayIcon from '../../../../../../assets/images/weter/playIcon.inline.svg'
import { LatestNewsCard } from '@components/gorod'

const background = (
  <StaticImage className={styles.background} src="../../../../../../assets/images/gorod/mainBg.png" alt="bg" />
)
const frame = (
  <StaticImage className={styles.frame} src="../../../../../../assets/images/gorod/mainFrame.png" alt="bg" />
)

const backgroundMobile = (
  <StaticImage quality={100} className={styles.backgroundMobile} src="../../../../../../assets/images/gorod/mainBgMobile.png" alt="bg" />
)

const frameMobile = (
  <StaticImage quality={100} className={styles.frameMobile} src="../../../../../../assets/images/gorod/mainFrameMobile.png" alt="bg" />
)

const socialMedia = {
  telegram: 'https://t.me/WETERNEWSGOROD',
  instagram: 'https://www.instagram.com/weter_world',
}

const backgroundsCount = 2
const delay = 5000

interface MainProps {
  newsMain?: () => void
  selectedNews: number
  openPresentationModal: () => void
  openNewsModal: () => void
  project?: 'weter' | 'voda'
  title?: string
  description?: string
  video?: string
  time?: string
}

export const Main: React.FC<MainProps> = ({
  newsMain,
  selectedNews,
  openPresentationModal,
  openNewsModal,
  project = 'weter',
  title = 'main.title',
  description = 'main.description',
  video = 'main.video.title',
  time = '11:18',
}) => {
  const [active, setActive] = useState(0)
  useInterval(() => setActive((index) => (index + 1) % backgroundsCount), delay)

  return (
    <div className={styles.root}>
      {background}
      {backgroundMobile}
      <div className={styles.info}>
        <div className={styles.description}>
          <Typography color={'inherit'} size={32} weight={700}>
            main.title
          </Typography>
          <Typography color={'inherit'} size={16} weight={500}>
            main.subtitle
          </Typography>
        </div>
        <div className={styles.bottom}>
          <div className={styles.hexagon}>
            <Hexagon className={styles.back} onClick={openPresentationModal} border cursor="pointer">
              <PlayIcon className={styles.icon} />
            </Hexagon>
          </div>
          <div className={styles.video}>
            <Typography color={'inherit'} className={styles.preview} size={24} weight={700}>
              main.video.title
            </Typography>
            <Typography color={'inherit'} missOnAlias className={styles.time} size={20} disableLocalize>
              9:12
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.news}>
        {frame}
        {frameMobile}
        <div className={styles.infoMobile}>
          <div className={styles.description}>
            <Typography color={'inherit'} size={32} weight={700}>
              main.title
            </Typography>
            <Typography color={'inherit'} size={16} weight={500}>
              main.subtitle
            </Typography>
          </div>
          <div className={styles.bottom}>
            <div className={styles.hexagon}>
              <Hexagon className={styles.back} onClick={openPresentationModal} border cursor="pointer">
                <PlayIcon className={styles.icon} />
              </Hexagon>
            </div>
            <div className={styles.video}>
              <Typography color={'inherit'} className={styles.preview} size={24} weight={700}>
                main.video.title
              </Typography>
              <Typography color={'inherit'} missOnAlias className={styles.time} size={20} disableLocalize>
                9:12
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.latestNews}>
          <LatestNewsCard
            selectedNews={selectedNews}
            openNewsModal={openNewsModal}
            news={newsMain}
          />
        </div>

      </div>
    </div>
  )
}
