import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Button, Link, Typography } from '@UIKit'

import * as styles from './Invest.module.scss'

interface InvestProps {
  openFormModal?: () => void
  countNumber?: number
  project: 'weter' | 'gorod' | 'voda'
}

const coins = {
  weter: (
    <StaticImage
      placeholder="blurred"
      src={'../../../../../assets/images/weter/coin.png'}
      alt="coin"
    />
  ),
  voda: (
    <StaticImage
      placeholder="blurred"
      src={'../../../../../assets/images/voda/coin.png'}
      alt="coin"
    />
  ),
  gorod: (
    <StaticImage
      placeholder="blurred"
      src={'../../../../../assets/images/gorod/coin.png'}
      alt="coin"
    />
  ),
}

const phones = {
  weter: (
    <StaticImage
      placeholder="blurred"
      quality={100}
      src={'../../../../../assets/images/voda/Invest/group/invest-phone2.png'}
      alt="group"
    />
  ),
  gorod: (
    <StaticImage
      placeholder="blurred"
      quality={100}
      src={'../../../../../assets/images/gorod/Invest/invest-phone.png'}
      alt="group"
    />
  ),
  voda: (
    <StaticImage
      placeholder="blurred"
      quality={100}
      src={'../../../../../assets/images/voda/Invest/group/invest-phone2.png'}
      alt="group"
    />
  ),
}

export const Invest: React.FC<InvestProps> = ({
  openFormModal,
  countNumber,
  project,
}) => {
  return (
    <section>
      <div className={styles.wrapper}>
        <div className={styles.visual}>&nbsp;</div>
        <div className={styles.visual2}>&nbsp;</div>
        <div className={styles.content}>
          <div className={styles.logo}>
            <StaticImage src={'../../../../../assets/images/voda/Invest/invest-icon.svg'} alt={'icon'} />
            <StaticImage src={'../../../../../assets/images/voda/Invest/invest-logo.svg'} alt={'icon'} />
          </div>
          <Typography className={styles.title} size={48} weight={500} color="default">
            landing.invest.title
          </Typography>
          <div className={styles.counter}>
            <Typography disableLocalize className={styles.title} size={64} weight={700} color="default">
              {countNumber}
            </Typography>
            <Typography size={16} weight={400} color="default">
              landing.invest.counter.investors
            </Typography>
          </div>
          <Typography className={styles.description} size={16} weight={400} color="default">
            landing.invest.description
          </Typography>
          <div className={styles.actions}>
            <Link to="https://my.tiaglin.com/{code}/auth" allowEn target="_blank">
              <Button className={styles.btn} fullWidth variant="primary" onClick={openFormModal}>
                <Typography className={styles.text} size={16} color="white">
                  landing.invest.register
                </Typography>
              </Button>
            </Link>
            <Link to="https://t.me/WETER_SUPPORT" allowEn target="_blank">
              <Button className={styles.button} fullWidth variant={project === 'gorod' ? 'light' : 'secondary'}>
                <Typography className={styles.text} size={16} color="white">
                  landing.invest.consult
                </Typography>
              </Button>
            </Link>
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.phone}>
            {phones[project]}
          </div>
          <div className={styles.safe}>
            {coins[project]}
          </div>
        </div>
        <div className={styles.background}>
          <StaticImage
            placeholder="blurred"
            src={'../../../../../assets/images/voda/Invest/invest-bg.png'}
            alt="group"
          />
        </div>
      </div>
    </section>
  )
}
