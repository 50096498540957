// extracted by mini-css-extract-plugin
export var root = "Author-module--root--c598b";
export var bio = "Author-module--bio--0df1e";
export var name = "Author-module--name--dca70";
export var description = "Author-module--description--623e3";
export var socialMedia = "Author-module--socialMedia--9cc5d";
export var link = "Author-module--link--7c3a4";
export var video = "Author-module--video--f1a2a";
export var title = "Author-module--title--bf38b";
export var image = "Author-module--image--5842b";
export var modal = "Author-module--modal--f6935";
export var icon = "Author-module--icon--76f0a";
export var hexagon = "Author-module--hexagon--061aa";
export var play = "Author-module--play--56337";