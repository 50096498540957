// extracted by mini-css-extract-plugin
export var root = "Visualization-module--root--b137f";
export var image = "Visualization-module--image--f6700";
export var bg = "Visualization-module--bg--e9ebf";
export var title = "Visualization-module--title--03ad9";
export var container = "Visualization-module--container--f011e";
export var modal = "Visualization-module--modal--78080";
export var vertical = "Visualization-module--vertical--84dbb";
export var slides = "Visualization-module--slides--571bf";
export var bottomSlide = "Visualization-module--bottomSlide--c049b";
export var topSlide = "Visualization-module--topSlide--82b9c";
export var top = "Visualization-module--top--98351";
export var bottom = "Visualization-module--bottom--b4707";