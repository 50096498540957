// extracted by mini-css-extract-plugin
export var title = "Committee-module--title--3dddd";
export var subtitle = "Committee-module--subtitle--09dce";
export var container = "Committee-module--container--8cc29";
export var blurFace = "Committee-module--blurFace--f0ecb";
export var blurFace2 = "Committee-module--blurFace2--e85ff";
export var faces = "Committee-module--faces--43708";
export var scroll = "Committee-module--scroll--83c53";
export var face = "Committee-module--face--0b902";
export var wrapper = "Committee-module--wrapper--de36d";
export var hexagon = "Committee-module--hexagon--a80f5";
export var modal = "Committee-module--modal--374e3";
export var content = "Committee-module--content--b470e";
export var avatar = "Committee-module--avatar--ba785";
export var info = "Committee-module--info--575fe";
export var icon = "Committee-module--icon--a0c33";
export var group = "Committee-module--group--89462";
export var country = "Committee-module--country--b0452";
export var social = "Committee-module--social--0d819";