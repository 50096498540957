/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as styles from './Patents.module.scss'

import Shield from '@assets/images/weter/shieldCheckOutlined.inline.svg'
import Download from '@assets/images/weter/download.inline.svg'
import { Button, Flag, Hexagon, Link, Typography } from '@UIKit'
import { Wrapper } from '@components/App/Wrapper'
import { PatentCountries } from '@components/sections/Patents/PatentCountries'
import { Modal } from '@components/App/Modal'
import { Patent } from '@graphql'
import cn from 'classnames'

interface PatentsProps {
  patents: Patent[]
  gorod?: boolean
}

export const Patents: React.FC<PatentsProps> = ({ patents, gorod }) => {
  const [selected, setSelected] = useState<Patent | null>(null)

  const confirmedPatents = patents.filter(({ status }) => status === 'CONFIRMED')
  const pendingPatents = patents.filter(({ status }) => status === 'PENDING')

  const handleCountry = (country: Patent) => {
    setSelected(country)
  }

  return (
    <section>
      <Wrapper>
        <div className={cn(styles.block, gorod && styles.lightBorder)}>
          <Hexagon className={styles.hexagon} size={124}>
            <Shield className={styles.shieldCheck} />
          </Hexagon>

          <Typography size={64} weight={500} className={styles.title}>
            landing.patents.title
          </Typography>

          <Typography className={styles.subtitle} size={24} weight={400}>
            landing.patents.description
          </Typography>
        </div>

        <PatentCountries
          title="landing.patents.pending.title"
          list={pendingPatents}
          variant="pending"
          gorodStyle={gorod}
          onClick={handleCountry}
        />
        <PatentCountries
          title="landing.patents.granted.title"
          list={confirmedPatents}
          variant="confirmed"
          gorodStyle={gorod}
          onClick={handleCountry}
        />
      </Wrapper>
      <Modal className={styles.modal} isOpen={selected !== null} onRequestClose={() => setSelected(null)}>
        {selected && (
          <>
            <div className={styles.header}>
              <Flag className={styles.flag} country={selected.code} />
              <Typography color={'white'} size={18} missOnAlias>
                {`country.${selected.code}`}
              </Typography>
            </div>
            {selected.image ? (
              <>
                <GatsbyImage image={selected.image} alt="patent" />
                <Button className={styles.button} suffix={<Download />} variant="primary">
                  <Link to={selected.pdf} download={selected.pdf} target="_blank">
                    <Typography color={'white'} size={18}>landing.patents.modal.download</Typography>
                  </Link>
                </Button>
              </>
            ) : (
              <div className={styles.disable}>
                <Typography size={32} weight={500}>
                  landing.patents.pending.files
                </Typography>
              </div>
            )}
          </>
        )}
      </Modal>
    </section>
  )
}
