import React from 'react'

import PlayIcon from '../../../../../../assets/images/weter/playIcon.inline.svg'
import LogoIcon from '../../../../../../assets/images/gorod.inline.svg'
import BrandIcon from '../../../../../../assets/images/gorod/gorod.inline.svg'

import { Hexagon, Typography } from '@UIKit'

import * as styles from './VideoPresentation.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

interface VideoPresentationProps {
  openPresentationModal: () => void
  bg: React.ReactNode
}
const background = (
  <StaticImage
    className={styles.image}
    src="../../../../../../assets/images/gorod/videoPresentationBackground.jpg"
    alt="presentation thumbnail"
    placeholder="blurred"
    quality={90}
  />
)

export const VideoPresentation: React.FC<VideoPresentationProps> = ({ openPresentationModal, bg = background }) => {
  return (
    <section className={styles.root} id="VideoPresentation">
      <StaticImage className={styles.background} src="../../../../../../assets/images/gorod/bodyBg.png" alt="bg" />
      <div className={styles.container}>
        {bg}
        <div className={styles.logo}>
          <LogoIcon />
          <BrandIcon />
        </div>
        <div onClick={openPresentationModal} className={styles.bottom}>
          <div className={styles.hexagon}>
            <Hexagon className={styles.back} border cursor="pointer">
              <PlayIcon className={styles.icon} />
            </Hexagon>
          </div>
          <div className={styles.video}>
            <Typography color={'inherit'} className={styles.preview} size={24} weight={700}>
              video.presentation.title
            </Typography>
            <Typography missOnAlias color={'inherit'} className={styles.time} size={20} disableLocalize>
              09:12
            </Typography>
          </div>
        </div>
      </div>
    </section>
  )
}
