// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const streams = {
  a: {
    id: 0,
    name: 'landing.streams.1.title',
    preview: 'officeDubai',
    description: 'streams.1.description',
    address: 'streams.1.address',
    timezone: 4,
    time: '09:00 AM - 06:00 PM',
    url: 'https://www.youtube.com/embed/FxEKqSp3zGM?rel=0&controls=0',
    live: false,
    technical: false,
  },
  b: {
    id: 1,
    name: 'landing.streams.2.title',
    preview: 'streamVladivostok',
    description: 'streams.2.description',
    address: 'streams.2.address',
    timezone: 10,
    url: 'https://video.sibset.ru/site/embed.html?id=3086&html5=true&logo=blank_screenshot.png&w=100%&h=100%&autostart=true',
    live: true,
    technical: true,
    // style: { transform: 'scale(1.15) translateY(32px)' },
  },
  c: {
    id: 2,
    name: 'landing.streams.3.title',
    preview: 'streamNSKRoof',
    description: 'streams.3.description',
    address: 'streams.3.address',
    timezone: 7,
    url: 'https://video.sibset.ru/site/embed.html?id=3074&html5=true&logo=blank_screenshot.png&w=100%&h=100%&autostart=true',
    live: true,
    technical: false,
  },
}
