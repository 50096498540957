/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Translate } from 'react-localize-alias'

import * as styles from './Author.module.scss'

import { Wrapper } from '@components/App/Wrapper'
import { Hexagon, Link, Typography, EmbedVideo } from '@UIKit'

import TelegramIcon from '../../../../../assets/images/weter/telegramIcon.inline.svg'
import InstagramIcon from '../../../../../assets/images/weter/instagramIcon.inline.svg'
import PlayIcon from '../../../../../assets/images/weter/playIcon.inline.svg'
import { Modal } from '@components/App/Modal'

const socialMedia = {
  instagram: 'https://instagram.com/denistiaglin',
}

export const Author = () => {
  const [modal, setModal] = useState(false)
  return (
    <>
      <section className={styles.root} id="Author">
        <div className={styles.bio}>
          <Typography className={styles.name} size={64}>
            landing.author.title
          </Typography>

          <div className={styles.socialMedia}>
            <Link className={styles.link} to={socialMedia.instagram} target="_blank">
              <InstagramIcon className={styles.icon} width={32} height={32} />
              <Typography size={24} disableLocalize>
                Instagram
              </Typography>
            </Link>
          </div>

          <Typography size={24} weight={700} className={styles.description}>
            landing.author.description
          </Typography>

          <div className={styles.video}>
            <Hexagon className={styles.hexagon} border cursor="pointer" onClick={() => setModal(true)}>
              <PlayIcon className={styles.play} />
            </Hexagon>
            <div>
              <Typography className={styles.title} size={24} weight={700}>
                landing.author.video.title
              </Typography>
              <Typography disableLocalize>40:10</Typography>
            </div>
          </div>
        </div>

        <StaticImage
          className={styles.image}
          src="../../../../../assets/images/author/authorWaistCropped.png"
          alt="Denis Tiaglin"
          placeholder="blurred"
          quality={80}
        />
      </section>

      <Modal className={styles.modal} isOpen={modal} onRequestClose={() => setModal(false)}>
        <EmbedVideo youtubeID={'1P8urgVOPUo'} />
      </Modal>
    </>
  )
}
