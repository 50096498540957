import React from 'react'
import * as styles from './Technology.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { Typography } from '@UIKit'
import LenghtIcon from '../../../../../../assets/images/gorod/Technology/length.inline.svg'
import cn from 'classnames'

export const Technology: React.FC = () => {
  const cards = [

    {
      id: 1,
      city: 'technology.card1.city',
      sizeBefore: '35',
      sizeAfter: '5',
      imageBefore: (
        <StaticImage quality={100} className={styles.bg} src="../../../../../../assets/images/gorod/Technology/firstExampleBefore.png" alt="before" />
      ),
      imageAfter: (
        <StaticImage quality={100} className={styles.bg} src="../../../../../../assets/images/gorod/Technology/firstExampleAfter.png" alt="after" />
      ),
    },

    {
      id: 2,
      city: 'technology.card2.city',
      sizeBefore: '64',
      sizeAfter: '9',
      imageBefore: (
        <StaticImage quality={100} className={styles.bg} src="../../../../../../assets/images/gorod/Technology/secondExampleBefore.png" alt="before" />
      ),
      imageAfter: (
        <StaticImage quality={100} className={styles.bg} src="../../../../../../assets/images/gorod/Technology/secondExampleAfter.png" alt="after" />
      ),
    },

    {
      id: 3,
      city: 'technology.card3.city',
      sizeBefore: '75',
      sizeAfter: '11',
      imageBefore: (
        <StaticImage quality={100} className={styles.bg} src="../../../../../../assets/images/gorod/Technology/thirdExampleBefore.png" alt="before" />
      ),
      imageAfter: (
        <StaticImage quality={100} className={styles.bg} src="../../../../../../assets/images/gorod/Technology/thirdExampleAfter.png" alt="after" />
      ),
    },

  ]

  return (
    <>
      <section className={styles.root}>
        <StaticImage quality={100} className={styles.background} src="../../../../../../assets/images/gorod/bodyBgBig.png" alt="bg" />
        <Typography className={styles.title} size={48} weight={700}>
          technology.title
        </Typography>
        <Typography className={styles.subtitle} size={24} weight={400}>
          technology.subtitle
        </Typography>
        <div className={styles.container}>
          <div className={styles.before}>
            {cards.map(({ id, city, sizeBefore, imageBefore }) => (
              <div className={styles.beforeBlock} key={id}>
                {imageBefore}
                <div className={styles.beforeCard}>
                  <Typography size={12} weight={400}>
                    technology.before
                  </Typography>
                  <Typography size={32} weight={700}>
                    {city}
                  </Typography>
                  <div className={styles.icon}>
                    <LenghtIcon />
                  </div>
                  <div className={styles.km}>
                    <Typography className={styles.orange} missOnAlias size={32} weight={700}>
                      {sizeBefore}km
                    </Typography>
                    <span className={cn(styles.small, styles.orange)}>2</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.after}>
            {cards.map(({ id, city, sizeAfter, imageAfter }) => (
              <div className={styles.afterBlock} key={id}>
                {imageAfter}
                <div className={styles.afterCard}>
                  <Typography size={12} weight={400}>
                    technology.after
                  </Typography>
                  <Typography size={32} weight={700}>
                    {city}
                  </Typography>
                  <div className={styles.icon}>
                    <LenghtIcon />
                  </div>
                  <div className={styles.km}>
                    <Typography className={styles.green} missOnAlias size={32} weight={700}>
                      {sizeAfter}km
                    </Typography>
                    <span className={cn(styles.small, styles.green)}>2</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
