/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'

import { Stream } from '@components/sections'
import { Wrapper } from '@components/App/Wrapper'

import * as styles from './Streams.module.scss'
import { Typography } from '@UIKit'
import { getTranslate } from 'react-localize-alias'

export const Streams: React.FC = ({ streams }) => {
  // const streams = [
  //   {
  //     id: 0,
  //     name: 'streams.1.title',
  //     preview: 'officeDubai',
  //     description: 'streams.1.description',
  //     address: 'streams.1.address',
  //     timezone: 4,
  //     time: '09:00 AM - 06:00 PM',
  //     url: 'https://www.youtube.com/embed/FxEKqSp3zGM?rel=0&controls=0',
  //     live: false,
  //   },
  //   {
  //     id: 1,
  //     name: 'streams.2.title',
  //     preview: 'streamVladivostok',
  //     description: 'streams.2.description',
  //     address: 'streams.2.address',
  //     timezone: 10,
  //     url: 'https://video.sibset.ru/site/embed.html?id=3086&html5=true&logo=blank_screenshot.png&w=100%&h=100%&autostart=true',
  //     live: true,
  //     // style: { transform: 'scale(1.15) translateY(32px)' },
  //   },
  //   {
  //     id: 2,
  //     name: 'streams.3.title',
  //     preview: 'streamNSKRoof',
  //     description: 'streams.3.description',
  //     address: 'streams.3.address',
  //     timezone: 7,
  //     url: 'https://video.sibset.ru/site/embed.html?id=3074&html5=true&logo=blank_screenshot.png&w=100%&h=100%&autostart=true',
  //     live: true,
  //   },
  // ]

  const [selectedStream, setSelectedStream] = useState(streams[0].id)

  const handleStream = (id: number) => {
    setSelectedStream(id)
  }

  return (
    <section>
      <Wrapper>
        <Typography className={styles.title} size={64} weight={500} color="default">
          landing.streams.title
        </Typography>
        <div className={styles.grid}>
          {streams.map((stream) => (
            <Stream key={stream.id} {...stream} active={selectedStream === stream.id} onClick={handleStream} />
          ))}
        </div>
      </Wrapper>
    </section>
  )
}
