/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef } from 'react'

import * as styles from './Schema.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { Carousel, Typography } from '@UIKit'
import cn from 'classnames'
import ArrowIcon from '../../../../../../assets/images/weter/arrowIcon.inline.svg'
import BrandIcon from '../../../../../../assets/images/weter/weter.inline.svg'
import LogoIcon from '../../../../../../assets/images/weter.inline.svg'
import Line from '../../../../../../assets/images/gorod/Schema/line.inline.svg'
import LineMobile from '../../../../../../assets/images/gorod/Schema/line-mobile.inline.svg'
import { useInView } from 'react-intersection-observer'

const slides = [
  {
    id: 1,
    index: 1,
    slide: (
      <div className={cn(styles.slide, styles.slide1)}>
        <Typography size={48} weight={700} className={styles.title}>
          schema.slide2.title
        </Typography>
        <Typography className={styles.description} size={20}>
          schema.slide2.subtitle
        </Typography>
        <span />
      </div>
    ),
  },
  {
    id: 2,
    index: 2,
    slide: (
      <div className={cn(styles.slide, styles.slide2)}>
        <Typography size={48} weight={700} className={styles.title}>
          schema.slide3.title
        </Typography>
        <Typography className={styles.description} size={20}>
          schema.slide3.subtitle
        </Typography>
        <span />
      </div>
    ),
  },
  {
    id: 3,
    index: 3,
    slide: (
      <div className={cn(styles.slide, styles.slide3)}>
        <Typography size={48} weight={700} className={styles.title}>
          schema.slide4.title
        </Typography>
        <Typography className={styles.description} size={20}>
          schema.slide4.subtitle
        </Typography>
        <span />
      </div>
    ),
  },
  {
    id: 4,
    index: 4,
    slide: (
      <div className={cn(styles.slide, styles.slide4)}>
        <Typography size={48} weight={700} className={styles.title}>
          schema.slide5.title
        </Typography>
        <Typography className={styles.description} size={20}>
          schema.slide5.subtitle
        </Typography>
        <span />
      </div>
    ),
  },
  {
    id: 5,
    index: 5,
    slide: (
      <div className={cn(styles.slide, styles.slide5)}>
        <Typography size={48} weight={700} className={styles.title}>
          schema.slide6.title
        </Typography>
        <Typography className={styles.description} size={20}>
          schema.slide6.subtitle
        </Typography>
        <span />
      </div>
    ),
  },
  {
    id: 6,
    index: 6,
    slide: (
      <div className={cn(styles.slide, styles.slide6)}>
        <Typography size={48} weight={700} className={styles.title}>
          schema.slide6.title
        </Typography>
        <Typography className={styles.description} size={20}>
          schema.slide6.subtitle
        </Typography>
        <span />
      </div>
    ),
  },
  {
    id: 7,
    index: 7,
    slide: (
      <div className={cn(styles.slide, styles.slide7)}>
        <Typography size={48} weight={700} className={styles.title}>
          schema.slide2.title
        </Typography>
        <Typography className={styles.description} size={20}>
          schema.slide2.subtitle
        </Typography>
        <span />
      </div>
    ),
  },
]

const levels = [
  {
    id: 1,
    level: (
      <StaticImage
        src="../../../../../../assets/images/gorod/Schema/level1.png"
        alt="level1"
        className={styles.level1}
        placeholder={'none'}
      />
    ),
    levelMobile: (
      <StaticImage
        src="../../../../../../assets/images/gorod/Schema/mobile-level1.png"
        alt="level1"
        className={styles.level1}
        placeholder={'none'}
      />
    ),
  },
  {
    id: 2,
    level: (
      <StaticImage
        src="../../../../../../assets/images/gorod/Schema/level2.png"
        alt="level2"
        className={styles.level2}
        placeholder={'none'}
      />
    ),
    levelMobile: (
      <StaticImage
        src="../../../../../../assets/images/gorod/Schema/mobile-level2.png"
        alt="level1"
        className={styles.level2}
        placeholder={'none'}
      />
    ),
  },
  {
    id: 3,
    level: (
      <StaticImage
        src="../../../../../../assets/images/gorod/Schema/level3.png"
        alt="level3"
        className={styles.level3}
        placeholder={'none'}
      />
    ),
    levelMobile: (
      <StaticImage
        src="../../../../../../assets/images/gorod/Schema/mobile-level3.png"
        alt="level1"
        className={styles.level3}
        placeholder={'none'}
      />
    ),
  },
  {
    id: 4,
    level: (
      <StaticImage
        src="../../../../../../assets/images/gorod/Schema/level4.png"
        alt="level4"
        className={styles.level4}
        placeholder={'none'}
      />
    ),
    levelMobile: (
      <StaticImage
        src="../../../../../../assets/images/gorod/Schema/mobile-level4.png"
        alt="level1"
        className={styles.level4}
        placeholder={'none'}
      />
    ),
  },
  {
    id: 5,
    level: (
      <StaticImage
        src="../../../../../../assets/images/gorod/Schema/level5.png"
        alt="level5"
        className={styles.level5}
        placeholder={'none'}
      />
    ),
    levelMobile: (
      <StaticImage
        src="../../../../../../assets/images/gorod/Schema/mobile-level5.png"
        alt="level1"
        className={styles.level5}
        placeholder={'none'}
      />
    ),
  },
]

export const Schema: React.FC = () => {
  const [activeSlideId, setActiveSlideId] = useState<number | null>(1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setInit] = useState<boolean | undefined>(undefined)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hintHidden, setHintHidden] = useState(true)

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.8,
    delay: 2500,
  })

  const handleSlideChange = (swiperCore) => {
    setHintHidden(false)
    const { realIndex } = swiperCore
    setActiveSlideId(realIndex + 1)
    if (realIndex === slides.length - 2) {
      setTimeout(() => {
        swiperCore.slideTo(0)
      }, 0)
    }
  }

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const handleClick = (clickedSlideId: number) => {
    // @ts-ignore
    if (clickedSlideId < activeSlideId && prevRef.current) {
      // @ts-ignore
      prevRef.current.click()
      // @ts-ignore
    } else if (clickedSlideId > activeSlideId && nextRef.current) {
      // @ts-ignore
      nextRef.current.click()
    }
  }

  return (
    <div className={styles.root} ref={ref}>
      <span className={styles.gradient} />
      <StaticImage
        className={styles.background}
        src="../../../../../../assets/images/gorod/Schema/schemaBackground.png"
        // loading="eager"
        alt="bg"
      />
      <StaticImage
        className={styles.backgroundMobile}
        src="../../../../../../assets/images/gorod/Schema/schema-mobile-Background.png"
        loading="eager"
        placeholder="blurred"
        alt="bg"
      />
      <div className={styles.container}>
        <div className={styles.carousel}>
          <Carousel
            onInit={() => setInit(true)}
            direction={'vertical'}
            slidesPerView={1.5}
            centeredSlides
            // autoplay
            // speed={20000}
            longSwipes={false}
            shortSwipes
            loopPreventsSlide
            allowTouchMove={false}
            loopedSlides={1}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            spaceBetween={0}
            loop={false}
            className={cn(styles.swiper, 'mySwiper')}
            onSlideChange={handleSlideChange}
            slides={slides.map(({ id, slide }) => (
              <div key={id} onClick={() => handleClick(id)}>
                {slide}
                <span className={styles.number}>0{id}</span>
              </div>
            ))}
          />
          <div className={styles.up} ref={prevRef} />
          <div className={styles.down} ref={nextRef} />
          <div className={cn(inView ? styles.hint : styles.hintHidden, !hintHidden ? styles.hintHidden : styles.hint)}>
            <StaticImage
              className={styles.cursor}
              src="../../../../../../assets/images/gorod/Schema/hint.png"
              alt="hint"
            />
            <StaticImage
              className={styles.cursorMobile}
              src="../../../../../../assets/images/gorod/Schema/hint-mobile.png"
              alt="hint-mobile"
            />
            <span className={styles.text}>
              <Typography missOnAlias size={32} weight={700}>
                Tap to scroll card
              </Typography>
            </span>
          </div>
          <div className={styles.navigation}>
            <div className={cn(styles.nav, activeSlideId === 1 && styles.grey)}>
              <ArrowIcon />
            </div>
            <span className={cn(styles.counter)}>0{activeSlideId}</span>
            <div className={cn(styles.nav, activeSlideId === 7 && styles.grey)}>
              <ArrowIcon />
            </div>
          </div>
        </div>
        <div className={cn(inView ? styles.levels : styles.opacity)}>
          {levels.map(({ id, level }) => (
            <div
              className={cn(
                styles.level,
                activeSlideId === 1 && styles.top1,
                activeSlideId === 2 && styles.top2,
                activeSlideId === 3 && styles.top3,
                activeSlideId === 4 && styles.top4,
                activeSlideId === 5 && styles.top5,
              )}
              key={id}
            >
              {level}
            </div>
          ))}
        </div>
        <div className={cn(inView ? styles.levelsMobile : styles.opacity)}>
          {levels.map(({ id, levelMobile }) => (
            <div
              className={cn(
                styles.level,
                activeSlideId === 1 && styles.top1,
                activeSlideId === 2 && styles.top2,
                activeSlideId === 3 && styles.top3,
                activeSlideId === 4 && styles.top4,
                activeSlideId === 5 && styles.top5,
              )}
              key={id}
            >
              {levelMobile}
            </div>
          ))}
        </div>
        {/* <div className={cn(!inView ? styles.tooltip : styles.hidden)}>
          <div className={styles.build}>
            <Typography className={styles.missMargin} color={'white'} size={24} weight={700}>
              schema.tooltip.ground
            </Typography>
          </div>
          <Line />
        </div>

        <div className={cn(!inView ? styles.tooltipMobile : styles.hidden)}>
          <div className={styles.build}>
            <Typography className={styles.missMargin} color={'white'} size={24} weight={700}>
              schema.tooltip.ground
            </Typography>
          </div>
        </div> */}
        <div className={cn(styles.tooltipMobile, activeSlideId === 5 ? styles.tooltipMobileUp : styles.hiddenMobileUp)}>
          <div className={styles.build}>
            <Typography color={'white'} size={24} weight={700}>
              schema.tooltip.building
            </Typography>
            <div className={styles.icon}>
              <LogoIcon />
              <BrandIcon />
            </div>
          </div>
          <div className={styles.line}>
            <LineMobile />
          </div>
        </div>

        <div className={cn(activeSlideId === 5 ? styles.tooltipUp : styles.hiddenUp)}>
          <div className={styles.build}>
            <Typography color={'white'} size={24} weight={700}>
              schema.tooltip.building
            </Typography>
            <div className={styles.icon}>
              <LogoIcon />
              <BrandIcon />
            </div>
          </div>
          <Line />
        </div>
      </div>
      <div style={{ background: 'pink' }}>kek</div>
    </div>
  )
}
