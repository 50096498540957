// extracted by mini-css-extract-plugin
export var root = "VideoPresentation-module--root--59a1a";
export var container = "VideoPresentation-module--container--d48b0";
export var background = "VideoPresentation-module--background--f5a2b";
export var image = "VideoPresentation-module--image--fcab1";
export var logo = "VideoPresentation-module--logo--2af9d";
export var bottom = "VideoPresentation-module--bottom--c2fa1";
export var hexagon = "VideoPresentation-module--hexagon--3c4b0";
export var video = "VideoPresentation-module--video--8da80";
export var preview = "VideoPresentation-module--preview--98760";
export var button = "VideoPresentation-module--button--0f9ee";
export var text = "VideoPresentation-module--text--db4e9";
export var time = "VideoPresentation-module--time--5f766";