// extracted by mini-css-extract-plugin
export var root = "Footer-module--root--97a3a";
export var grid = "Footer-module--grid--af9c1";
export var logo = "Footer-module--logo--9a689";
export var year = "Footer-module--year--b5eff";
export var text = "Footer-module--text--7b30f";
export var address = "Footer-module--address--bbe8d";
export var contacts = "Footer-module--contacts--4fde1";
export var social = "Footer-module--social--4617a";
export var link = "Footer-module--link--6129d";
export var gridMobile = "Footer-module--gridMobile--59eae";
export var top = "Footer-module--top--8f93b";
export var bottom = "Footer-module--bottom--b7a21";
export var info = "Footer-module--info--60c89";
export var center = "Footer-module--center--2e9d8";