// extracted by mini-css-extract-plugin
export var wrapper = "Invest-module--wrapper--7cf69";
export var counter = "Invest-module--counter--597c4";
export var content = "Invest-module--content--3d550";
export var title = "Invest-module--title--31964";
export var description = "Invest-module--description--8502f";
export var actions = "Invest-module--actions--5c43a";
export var button = "Invest-module--button--317f3";
export var btn = "Invest-module--btn--4387b";
export var text = "Invest-module--text--c5f24";
export var group = "Invest-module--group--4a3d1";
export var chart = "Invest-module--chart--6b289";
export var safe = "Invest-module--safe--d7415";
export var phone = "Invest-module--phone--d7785";
export var background = "Invest-module--background--3caf2";
export var logo = "Invest-module--logo--4f1f7";
export var visual = "Invest-module--visual--475b3";
export var visual2 = "Invest-module--visual2--256b1";