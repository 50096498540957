/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Button, Link, Typography } from '@UIKit'

import WeterLogoIcon from '../../../../../../assets/images/weter.inline.svg'
import WeterBrandIcon from '../../../../../../assets/images/weter/weter.inline.svg'

import GorodLogoIcon from '../../../../../../assets/images/gorod.inline.svg'
import GorodBrandIcon from '../../../../../../assets/images/gorod/gorod.inline.svg'

import VodaLogoIcon from '../../../../../../assets/images/voda.inline.svg'
import VodaBrandIcon from '../../../../../../assets/images/voda/voda.inline.svg'

import BurgerMenu from '../../../../../../assets/images/gorod/system.inline.svg'

import TelegramIcon from '../../../../../../assets/images/weter/telegramIcon.inline.svg'
import InstagramIcon from '../../../../../../assets/images/weter/instagramIcon.inline.svg'

import CrossIcon from '@assets/images/weter/cross.inline.svg'

import * as styles from './Menu.module.scss'
import cn from 'classnames'
import { LanguageSelect } from '@components/App'

const logoWeter = (
  <>
    <WeterLogoIcon width={40} height={40} />
    <WeterBrandIcon />
  </>
)

const logoGorod = (
  <>
    <GorodLogoIcon width={40} height={40} />
    <GorodBrandIcon />
  </>
)

const logoVoda = (
  <>
    <VodaLogoIcon width={40} height={40} />
    <VodaBrandIcon />
  </>
)

export const Menu: React.FC = () => {
  const socialMedia = {
    telegram: 'https://t.me/WETERNEWSGOROD',
    instagram: 'https://www.instagram.com/weter_world',
  }
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  return (
    <div className={styles.root}>
      <div className={cn(styles.button, { [styles.visible]: !isVisible, [styles.hidden]: isVisible })} onClick={toggleVisibility}>
        <BurgerMenu />
      </div>

      <div className={cn(styles.grid, { [styles.visible]: isVisible, [styles.hidden]: !isVisible })}>
        <div className={styles.actions}>
          <div className={styles.logo}>
            {logoGorod}

          </div>
          <div className={styles.close} onClick={toggleVisibility}>
            <CrossIcon />
          </div>

        </div>
        <Link className={cn(styles.item, styles.cab)} to={'https://my.tiaglin.com/{code}/'} allowEn>
          <Button className={styles.btn}>
            <Typography weight={700} size={18}>landing.header.cabinet</Typography>
          </Button>
        </Link>

        <div className={styles.other}>
          <Typography missOnAlias size={16} weight={400}>Другие проекты</Typography>
          <div className={styles.logoOther}>
            {logoWeter}
          </div>
          <div className={styles.logoOther}>
            {logoVoda}
          </div>
        </div>

        <div className={styles.media}>
          <Typography missOnAlias size={16} weight={400}>Мы в соцсетях</Typography>
          <div className={styles.social}>
            <TelegramIcon />
            <InstagramIcon />
          </div>
        </div>

        <div className={styles.languageSelect}>
          <Typography missOnAlias size={16} weight={400}>Язык</Typography>
          <LanguageSelect className={styles.language} />
        </div>
      </div>
    </div>
  )
}
