const searchValues = {
  enter: null,
  weter: 'weter',
  gorod: 'gorod',
}

const api = 'https://api.denistiaglin.com'
// typeof window !== 'undefined' && window.location.hostname !== 'localhost'
//   // ? window.location.origin.replace(searchValues[process.env.PROJECT], 'api')
//   ? 'https://weter.tiaglin.com'.replace(searchValues[process.env.PROJECT], 'api')
//   : process.env.API_DOMAIN || 'https://api.tiaglin.com'

const getApi = (path) => `${api}${path.startsWith('/') ? '' : '/'}` + path

module.exports = {
  getApi,
}
