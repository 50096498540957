import fetch from 'node-fetch'
import { getApi } from './_utils'

export const newsTypes = `
    type News implements Node {
        id:	ID
        attachments: [String]
        isActive: Boolean
        isSharePriceChanged: Boolean
        influencedProjectIds: [Int]
        viewsCount: Int
        likesCount: Int
        isUserLikedIt: Boolean
        publishedAt: String
        updatedAt: String
        createdAt: String
        shortDescription: [Content]
        description: [Content]
        title: [Content]
        metadata: NewsMetadata
        mainImage: File @link
        attachmentImages: [NewsAttachmentImage] @link
    }
    type NewsMetadata {
        mainImg: String
    }
    type NewsAttachmentImage implements Node {
        id: ID
        url: String
        image: File @link
    }
`

export const getAllNewsData = () =>
  fetch(getApi('/application/news?limit=5000&page=1&language=en')).then((data) => data.json())

export const getNewsData = (id) => fetch(getApi(`/application/news/${id}`)).then((data) => data.json())
