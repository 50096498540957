// extracted by mini-css-extract-plugin
export var root = "PatentCountries-module--root--271f4";
export var title = "PatentCountries-module--title--945d6";
export var countries = "PatentCountries-module--countries--70fe6";
export var country = "PatentCountries-module--country--11606";
export var name = "PatentCountries-module--name--0ca2f";
export var flag = "PatentCountries-module--flag--cc969";
export var svg = "PatentCountries-module--svg--9e40a";
export var opacity = "PatentCountries-module--opacity--2e810";
export var status = "PatentCountries-module--status--3a0bc";
export var clock = "PatentCountries-module--clock--21f24";