// extracted by mini-css-extract-plugin
export var root = "Future-module--root--bcd26";
export var zeroPadding = "Future-module--zeroPadding--e42a3";
export var gorodBg = "Future-module--gorodBg--52319";
export var weterBg = "Future-module--weterBg--17afd";
export var wrapper = "Future-module--wrapper--023c4";
export var button = "Future-module--button--e30e2";
export var projects = "Future-module--projects--01a81";
export var mt = "Future-module--mt--4626e";
export var card = "Future-module--card--03b23";
export var icon = "Future-module--icon--8b67e";
export var weterGradient = "Future-module--weterGradient--a519e";
export var gorodGradient = "Future-module--gorodGradient--91019";
export var weterGradientBottom = "Future-module--weterGradientBottom--df8b4";
export var blackOpacity = "Future-module--blackOpacity--8b334";
export var opacity = "Future-module--opacity--993dd";
export var gorodBtn = "Future-module--gorodBtn--8a264";
export var text = "Future-module--text--a0749";