import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './Future.module.scss'

import { Button, Link, Typography } from '@UIKit'
import { Card } from '@components/enter'

import WeterIcon from '@assets/images/weter.inline.svg'
import GorodIcon from '@assets/images/gorod.inline.svg'
import VodaIcon from '@assets/images/voda.inline.svg'
import cn from 'classnames'

interface FutureProps {
  theme?: 'dark' | 'light'
}

export const Future: React.FC<FutureProps> = ({ theme = 'dark' }) => {
  return (
    <section
      className={cn(
        styles.root,
        theme === 'dark' && styles.mb,
        theme === 'light' && styles.zeroPadding,
      )}
    >
      {theme === 'dark' && (
        <StaticImage
          className={styles.weterBg}
          quality={100}
          placeholder="blurred"
          src={'../../../../../assets/images/weter/earth.png'}
          alt="world map"
        />
      )}

      {theme === 'light' && (
        <StaticImage
          className={styles.gorodBg}
          quality={100}
          placeholder="blurred"
          src={'../../../../../assets/images/gorod/earth.png'}
          alt="world map"
        />
      )}

      <div className={styles.wrapper}>
        {theme === 'dark' && (
          <>
            <span className={styles.weterGradient} />
            <span className={styles.weterGradientBottom} />
          </>
        )}
        {theme === 'light' && (
          <span className={styles.gorodGradient} />
        )}

        <div className={cn(styles.projects, theme === 'dark' && styles.mt)}>
          <div className={cn(styles.card, styles.opacity)}>
            <Card
              disableButton
              disableAnimation
              icon={
                <span className={cn(styles.icon, theme === 'light' && styles.iconBlue)}>
                  <WeterIcon />
                </span>
              }
              // TODO: refactor global aliases
              title={'W.E.T.E.R'}
              description={'Wind Energy Schema Era Renewable'}
              to={'https://weter.tiaglin.com/{code}/'}
            />
          </div>

          <div className={cn(styles.card, styles.opacity)}>
            <Card
              disableButton
              disableAnimation
              icon={
                <span className={cn(styles.icon, theme === 'light' && styles.iconBlue)}>
                  <GorodIcon />
                </span>
              }
              // TODO: refactor global aliases
              title={'GOROD L.E.S'}
              description={'Logistic Eco System'}
              to={'https://gorod.tiaglin.com/{code}/'}
            />
          </div>

          <div className={cn(styles.card, styles.opacity)}>
            <Card
              disableButton
              disableAnimation
              icon={
                <span className={cn(styles.icon, theme === 'light' && styles.iconBlue)}>
                  <VodaIcon />
                </span>
              }
              title={'V.O.D.A.'}
              description={'Hydroelectric power station'}
              to={'https://voda.tiaglin.com/{code}/'}
            />
          </div>
          <Link className={styles.button} to="https://tiaglin.com/{code}/">
            <Button variant="primary" fullWidth>
              <Typography className={styles.text} size={20} color="white" missOnAlias>
                landing.future.button
              </Typography>
            </Button>
          </Link>

        </div>
      </div>
    </section>
  )
}
