/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react'
import { Link, Typography } from '@UIKit'

import WeterLogoIcon from '../../../../../assets/images/weter.inline.svg'
import WeterBrandIcon from '../../../../../assets/images/weter/weter.inline.svg'

import GorodLogoIcon from '../../../../../assets/images/gorod.inline.svg'
import GorodBrandIcon from '../../../../../assets/images/gorod/gorod.inline.svg'

import VodaLogoIcon from '../../../../../assets/images/voda.inline.svg'
import VodaBrandIcon from '../../../../../assets/images/voda/voda.inline.svg'

import TelegramIcon from '../../../../../assets/images/weter/telegramIcon.inline.svg'
import InstagramIcon from '../../../../../assets/images/weter/instagramIcon.inline.svg'

import TermsAndPrivacyEn from '../../../../../assets/Security_Policy_and_Consent_for_the_Processing_of_Personal_Data_En.pdf'
import TermsAndPrivacyRu from '../../../../../assets/Security_Policy_and_Consent_for_the_Processing_of_Personal_Data_Ru.pdf'

import * as styles from './Footer.module.scss'
import { AppContext } from '@components/App'

const icons = {
  weter: (
    <>
      <WeterLogoIcon width={40} height={40} />
      <WeterBrandIcon />
    </>
  ),
  gorod: (
    <>
      <GorodLogoIcon width={40} height={40} />
      <GorodBrandIcon />
    </>
  ),
  voda: (
    <>
      <VodaLogoIcon width={40} height={40} />
      <VodaBrandIcon />
    </>
  ),
}

const socialMedia = {
  telegram: 'https://t.me/WETERNEWSGOROD',
  instagram: 'https://www.instagram.com/denis_tiaglin',
}

interface FooterProps {
  project: 'weter' | 'gorod' | 'voda'
}
export const Footer: React.FC<FooterProps> = ({ project }) => {
  const { langCode } = useContext(AppContext)
  const termsAndPrivacyFile = langCode === 'ru' ? TermsAndPrivacyRu : TermsAndPrivacyEn
  const downloadLabel =
    langCode === 'ru'
      ? 'Политика_в_отношении_обработки_персональных_данных'
      : 'Security_Policy_and_Consent_for_the_Processing_of_Personal_Data'

  return (
    <footer className={styles.root}>
      <div className={styles.grid}>
        <div className={styles.logo}>{icons[project]}</div>
        <div className={styles.year}>
          <Typography color="inherit" size={20} weight={700}>
            footer.project.founded
          </Typography>
        </div>
        <div className={styles.address}>
          <Typography
            disableLocalize
            className={styles.text}
            size={16}
            weight={500}
            color="inherit"
            onClick={() => navigator.clipboard.writeText('UAE, Dubai, DIP 1, W10')}
          >
            Copyright © 2022 Weter LLC. All rights reserved. Tiaglin Hub, UAE, Dubai, DIP 1, W10
            <span>{' ZIP 00000'}</span>
          </Typography>
        </div>
        <div className={styles.contacts}>
          <a href={termsAndPrivacyFile} target="_blanc">
            <Typography disableLocalize className={styles.text} size={16} weight={500} color="inherit">
              Terms & privacy policy
            </Typography>
          </a>

          <Link to={'mailto:info@tiaglin.com'}>
            <Typography disableLocalize className={styles.text} size={16} weight={500} color="inherit">
              info@tiaglin.com
            </Typography>
          </Link>
          <Link to={'tel:+9710585304380'}>
            <Typography disableLocalize className={styles.text} size={16} weight={500} color={'inherit'}>
              +971 058 530 4380
            </Typography>
          </Link>
        </div>
        <div className={styles.social}>
          <Link className={styles.link} to={socialMedia.telegram} target="_blank">
            <TelegramIcon width={32} height={32} />
          </Link>
          <Link className={styles.link} to={socialMedia.instagram} target="_blank">
            <InstagramIcon width={32} height={32} />
          </Link>
        </div>
      </div>

      <div className={styles.gridMobile}>
        <div className={styles.top}>
          <div className={styles.info}>
            <div className={styles.logo}>{icons[project]}</div>
            <div className={styles.year}>
              <Typography color="inherit" size={20} weight={700}>
                footer.project.founded
              </Typography>
            </div>
          </div>

          <div className={styles.social}>
            <Link className={styles.link} to={socialMedia.telegram} target="_blank">
              <TelegramIcon width={32} height={32} />
            </Link>
            <Link className={styles.link} to={socialMedia.instagram} target="_blank">
              <InstagramIcon width={32} height={32} />
            </Link>
          </div>
        </div>
        <div className={styles.center}>
          <Typography
            disableLocalize
            className={styles.text}
            size={16}
            weight={500}
            color="inherit"
            onClick={() => navigator.clipboard.writeText('UAE, Dubai, DIP 1, W10')}
          >
            Tiaglin Hub, UAE, Dubai, DIP 1, W10
            <span>{' ZIP 00000'}</span>
          </Typography>
          <Link to={'mailto:info@tiaglin.com'}>
            <Typography disableLocalize className={styles.text} size={16} weight={500} color="inherit">
              info@tiaglin.com
            </Typography>
          </Link>
          <Link to={'tel:+9710585304380'}>
            <Typography disableLocalize className={styles.text} size={16} weight={500} color={'inherit'}>
              +971 058 530 4380
            </Typography>
          </Link>
        </div>
        <div className={styles.bottom}>
          <Typography disableLocalize className={styles.text} size={16} weight={500} color="inherit">
            Copyright © 2024 Weter LLC. All rights reserved.
          </Typography>
          <a href={termsAndPrivacyFile} target="_blanc">
            <Typography disableLocalize className={styles.text} size={16} weight={500} color="inherit">
              Terms & privacy policy
            </Typography>
          </a>
        </div>
      </div>
    </footer>
  )
}
