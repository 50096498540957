// extracted by mini-css-extract-plugin
export var root = "LatestNewsCard-module--root--3704e";
export var bar = "LatestNewsCard-module--bar--4a2c4";
export var title = "LatestNewsCard-module--title--25434";
export var description = "LatestNewsCard-module--description--c76ce";
export var count = "LatestNewsCard-module--count--058e3";
export var p = "LatestNewsCard-module--p--05081";
export var cover = "LatestNewsCard-module--cover--9f412";
export var icon = "LatestNewsCard-module--icon--236e6";
export var text = "LatestNewsCard-module--text--51f0d";
export var info = "LatestNewsCard-module--info--c91f7";
export var divider = "LatestNewsCard-module--divider--b15af";
export var indicators = "LatestNewsCard-module--indicators--d94c5";
export var indicator = "LatestNewsCard-module--indicator--ea115";
export var actions = "LatestNewsCard-module--actions--da0c5";
export var button = "LatestNewsCard-module--button--fa774";