import React from 'react'

import * as styles from './Benefit.module.scss'
import 'swiper/css'
import 'swiper/css/pagination'

import TopIcon1 from '../../../../../../assets/images/gorod/Benefit/Top/TopIcon1.inline.svg'
import TopIcon2 from '../../../../../../assets/images/gorod/Benefit/Top/TopIcon2.inline.svg'
import TopIcon3 from '../../../../../../assets/images/gorod/Benefit/Top/TopIcon3.inline.svg'
import TopIcon4 from '../../../../../../assets/images/gorod/Benefit/Top/TopIcon4.inline.svg'
import TopIcon5 from '../../../../../../assets/images/gorod/Benefit/Top/TopIcon5.inline.svg'
import TopIcon6 from '../../../../../../assets/images/gorod/Benefit/Top/TopIcon6.inline.svg'
import TopIcon7 from '../../../../../../assets/images/gorod/Benefit/Top/TopIcon7.inline.svg'
import TopIcon8 from '../../../../../../assets/images/gorod/Benefit/Top/TopIcon8.inline.svg'
import TopIcon9 from '../../../../../../assets/images/gorod/Benefit/Top/TopIcon9.inline.svg'
import TopIcon10 from '../../../../../../assets/images/gorod/Benefit/Top/TopIcon10.inline.svg'

import BottomIcon1 from '../../../../../../assets/images/gorod/Benefit/Bottom/BottomIcon1.inline.svg'
import BottomIcon2 from '../../../../../../assets/images/gorod/Benefit/Bottom/BottomIcon2.inline.svg'
import BottomIcon3 from '../../../../../../assets/images/gorod/Benefit/Bottom/BottomIcon3.inline.svg'
import BottomIcon4 from '../../../../../../assets/images/gorod/Benefit/Bottom/BottomIcon4.inline.svg'
import BottomIcon5 from '../../../../../../assets/images/gorod/Benefit/Bottom/BottomIcon5.inline.svg'
import BottomIcon6 from '../../../../../../assets/images/gorod/Benefit/Bottom/BottomIcon6.inline.svg'
import BottomIcon7 from '../../../../../../assets/images/gorod/Benefit/Bottom/BottomIcon7.inline.svg'
import BottomIcon8 from '../../../../../../assets/images/gorod/Benefit/Bottom/BottomIcon8.inline.svg'
import BottomIcon9 from '../../../../../../assets/images/gorod/Benefit/Bottom/BottomIcon9.inline.svg'

import { StaticImage } from 'gatsby-plugin-image'
import { Typography } from '@UIKit'
import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, FreeMode } from 'swiper/modules'

export const Benefit: React.FC = () => {
  const top = [

    {
      id: 1,
      ground: 1,
      icon: <TopIcon1 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Top/ground1_separatedRoads.png"
          alt="Separate pedestrian and transport network"
        />
      ),
      title: 'benefit.slide1.ground1.title',
      cropped: true,
      line: 1,
    },

    {
      id: 2,
      ground: 1,
      icon: <TopIcon2 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Top/ground1_lowTrafficDensity.png"
          alt="Low traffic density"
        />
      ),
      title: 'benefit.slide2.ground1.title',
    },

    {
      id: 3,
      ground: 1,
      icon: <TopIcon3 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Top/ground1_fastDelivery.png"
          alt="Fast delivery of goods and services"
        />
      ),
      title: 'benefit.slide3.ground1.title',
    },

    {
      id: 4,
      ground: 1,
      icon: <TopIcon4 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Top/ground1_automatedWarehouses.png"
          alt="Automated warehouses with direct access to logistics"
        />
      ),
      title: 'benefit.slide4.ground1.title',
    },

    {
      id: 5,
      ground: 2,
      icon: <TopIcon5 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Top/ground2_wasteReclamation.png"
          alt="The possibility of reclamation of waste products"
        />
      ),
      title: 'benefit.slide1.ground2.title',
      line: 2,
    },

    {
      id: 6,
      ground: 2,
      icon: <TopIcon6 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Top/ground2_UnitaryAirConditioningSystem.png"
          alt="Unitary air conditioning system"
        />
      ),
      title: 'benefit.slide2.ground2.title',
    },

    {
      id: 7,
      ground: 2,
      icon: <TopIcon7 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Top/ground2_airmail.png"
          alt="Updating the airmail"
        />
      ),
      title: 'benefit.slide3.ground2.title',
    },

    {
      id: 8,
      ground: 3,
      icon: <TopIcon8 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Top/ground3_medicalCare.png"
          alt="Fast and affordable medical care"
        />
      ),
      title: 'benefit.slide1.ground3.title',
      line: 3,
    },

    {
      id: 9,
      ground: 4,
      icon: <TopIcon9 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Top/ground4_oneWithNature.png"
          alt="One with nature"
        />
      ),
      title: 'benefit.slide1.ground4.title',
      line: 4,
    },

    {
      id: 10,
      ground: 4,
      icon: <TopIcon10 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Top/ground4_reductionOfWater.png"
          alt="Reduction of water consumption by green spaces by 50per"
        />
      ),
      title: 'benefit.slide2.ground4.title',
      cropped: true,
    },

  ]

  const bottom = [

    {
      id: 1,
      ground: 1,
      icon: <BottomIcon1 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Bottom/ground1_verticalFarms.png"
          alt="Introduction of vertical farms and aquaculture"
        />
      ),
      title: 'benefit.slide5.ground1.title',
      line: 1,
    },

    {
      id: 2,
      ground: 1,
      icon: <BottomIcon2 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Bottom/ground1_reducingTheCostOfProduction.png"
          alt="Reducing the cost of products and services"
        />
      ),
      title: 'benefit.slide6.ground1.title',
    },

    {
      id: 3,
      ground: 1,
      icon: <BottomIcon3 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Bottom/ground1_closedLoopEconomics.png"
          alt="Closed-loop economics"
        />
      ),
      title: 'benefit.slide7.ground1.title',
    },

    {
      id: 4,
      ground: 2,
      icon: <BottomIcon4 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Bottom/ground2_backboneCommunicationSystems.png"
          alt="Compactness and accessibility of backbone communication systems"
        />
      ),
      title: 'benefit.slide4.ground2.title',
      line: 2,
    },

    {
      id: 5,
      ground: 2,
      icon: <BottomIcon5 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Bottom/ground2_withoutHarmToNature.png"
          alt="Without harm to nature"
        />
      ),
      title: 'benefit.slide5.ground2.title',
    },

    {
      id: 6,
      ground: 2,
      icon: <BottomIcon6 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Bottom/ground2_organizationOfTheWorkspace.png"
          alt="Modular organization of the workspace"
        />
      ),
      title: 'benefit.slide6.ground2.title',
    },

    {
      id: 7,
      ground: 3,
      icon: <BottomIcon7 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Bottom/ground3_unifiedSmartCitySystem.png"
          alt="Implementation of the IOT - unified smart city system"
        />
      ),
      title: 'benefit.slide2.ground3.title',
      line: 3,
    },

    {
      id: 8,
      ground: 3,
      icon: <BottomIcon8 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Bottom/ground3_publicElectricTransport.png"
          alt="Comfortable and affordable public electric transport"
        />
      ),
      title: 'benefit.slide3.ground3.title',
    },

    {
      id: 9,
      ground: 4,
      icon: <BottomIcon9 />,
      bg: (
        <StaticImage
          className={styles.bg}
          src="../../../../../../assets/images/gorod/Benefit/Bottom/ground4_reducingNoise.png"
          alt="Reducing noise perceived and affecting humans"
        />
      ),
      title: 'benefit.slide3.ground4.title',
      line: 4,
    },

  ]

  const sum = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ]

  return (
    <section className={styles.root}>
      <div className={styles.title}>
        <Typography size={48} weight={700}>
          benefit.title
        </Typography>
      </div>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={0}
        centeredSlides
        loop
        autoplay={{
          delay: 0,
          disableOnInteraction: true,
        }}
        freeMode
        speed={400000}
        modules={[Autoplay, FreeMode]}
        className={cn(styles.carousel, 'mySwiper')}
      >
        {sum.map(({ id }) => (
          <SwiperSlide key={id} className={styles.container}>
            <div className={styles.top}>
              {top.map(({ id, icon, bg, title, cropped, ground, line }) => (
                <div
                  className={cn(
                    styles.ground,
                    cropped === true && styles.crop,
                    line === 1 && styles.pinkLine,
                    line === 2 && styles.yellowLine,
                    line === 3 && styles.blueLine,
                    line === 4 && styles.greenLine,
                  )}
                  key={id}
                >
                  <span
                    className={styles.icon}
                  >
                    {icon}
                  </span>
                  {bg}
                  <div className={styles.info}>
                    <Typography
                      color={'white'}
                      missOnAlias
                      size={24}
                      weight={700}
                      className={cn(
                        styles.frame,
                        ground === 1 && styles.pink,
                        ground === 2 && styles.yellow,
                        ground === 3 && styles.blue,
                        ground === 4 && styles.green,
                      )}
                    >
                      GROUND {ground}
                    </Typography>
                    <Typography color={'white'} size={32} weight={700}>
                      {title}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.bottom}>
              {bottom.map(({ id, icon, bg, title, ground, line }) => (
                <div
                  className={cn(
                    styles.ground,
                    line === 1 && styles.pinkLine,
                    line === 2 && styles.yellowLine,
                    line === 3 && styles.blueLine,
                    line === 4 && styles.greenLine,
                  )}
                  key={id}
                >
                  <span
                    className={styles.icon}
                  >
                    {icon}
                  </span>
                  {bg}
                  <div className={styles.info}>
                    <Typography
                      color={'white'}
                      missOnAlias
                      size={24}
                      weight={700}
                      className={cn(
                        styles.frame,
                        ground === 1 && styles.pink,
                        ground === 2 && styles.yellow,
                        ground === 3 && styles.blue,
                        ground === 4 && styles.green,
                      )}
                    >
                      GROUND {ground}
                    </Typography>
                    <Typography color={'white'} size={32} weight={700}>
                      {title}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </SwiperSlide>
        ),
        )}

      </Swiper>

    </section>
  )
}
