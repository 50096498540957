// extracted by mini-css-extract-plugin
export var root = "CardNews-module--root--48d7b";
export var cover = "CardNews-module--cover--50909";
export var img = "CardNews-module--img--0ced6";
export var date = "CardNews-module--date--70978";
export var text = "CardNews-module--text--29fd7";
export var title = "CardNews-module--title--b16d7";
export var description = "CardNews-module--description--248e4";
export var info = "CardNews-module--info--abf6b";
export var divider = "CardNews-module--divider--2fca9";
export var indicators = "CardNews-module--indicators--98c11";
export var actions = "CardNews-module--actions--2d4aa";
export var link = "CardNews-module--link--1a1a8";
export var icon = "CardNews-module--icon--9e6a7";