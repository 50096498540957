import React, { useState } from 'react'

import * as styles from './Visualization.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, FreeMode } from 'swiper/modules'
import { StaticImage } from 'gatsby-plugin-image'
import { Typography } from '@UIKit'
import { Modal } from '@components/App/Modal'

interface VisualizationProps {
  useVisualizationHook: () => {
    cropped: any[];
    full: any[];
  };
}
export const Visualization: React.FC<VisualizationProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const openModal = ({ image }: { image: any }) => {
    setSelectedImage(image)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const top = [
    {
      id: 1,
      img: <StaticImage
        className={styles.image}
        src="../../../../../../assets/images/gorod/Visualization/visual-cropped-1.png"
        alt="top"
      />,
      full: <StaticImage
        src="../../../../../../assets/images/gorod/Visualization/visual-full-1.jpeg"
        alt="top"
      />,
      title: 'visualization.slide1.title',
    },
    {
      id: 2,
      img: <StaticImage
        className={styles.image}
        src="../../../../../../assets/images/gorod/Visualization/visual-cropped-3.png"
        alt="top"
      />,
      full: <StaticImage
        src="../../../../../../assets/images/gorod/Visualization/visual-full-3.jpeg"
        alt="top"
      />,
      title: 'visualization.slide2.title',
    },
    {
      id: 3,
      img: <StaticImage
        className={styles.image}
        src="../../../../../../assets/images/gorod/Visualization/visual-cropped-5.png"
        alt="top"
      />,
      full: <StaticImage
        src="../../../../../../assets/images/gorod/Visualization/visual-full-5.jpeg"
        alt="top"
      />,
      title: 'visualization.slide3.title',
    },
    {
      id: 4,
      img: <StaticImage
        className={styles.image}
        src="../../../../../../assets/images/gorod/Visualization/visual-cropped-7.png"
        alt="top"
      />,
      full: <StaticImage
        src="../../../../../../assets/images/gorod/Visualization/visual-full-7.jpeg"
        alt="top"
      />,
      title: 'visualization.slide4.title',
    },
  ]
  const bottom = [
    {
      id: 1,
      img: <StaticImage
        className={styles.image}
        src="../../../../../../assets/images/gorod/Visualization/visual-cropped-2.png"
        alt="bottom"
      />,
      full: <StaticImage
        src="../../../../../../assets/images/gorod/Visualization/visual-full-2.png"
        alt="bottom"
      />,
      title: 'visualization.slide5.title',
    },
    {
      id: 2,
      img: <StaticImage
        className={styles.image}
        src="../../../../../../assets/images/gorod/Visualization/visual-cropped-4.png"
        alt="bottom"
      />,
      full: <StaticImage
        src="../../../../../../assets/images/gorod/Visualization/visual-full-4.jpeg"
        alt="bottom"
      />,
      title: 'visualization.slide6.title',
    },
    {
      id: 3,
      img: <StaticImage
        className={styles.image}
        src="../../../../../../assets/images/gorod/Visualization/visual-cropped-6.png"
        alt="bottom"
      />,
      full: <StaticImage
        src="../../../../../../assets/images/gorod/Visualization/visual-full-6.jpeg"
        alt="bottom"
      />,
      title: 'visualization.slide7.title',
    },
    {
      id: 4,
      img: <StaticImage
        className={styles.image}
        src="../../../../../../assets/images/gorod/Visualization/visual-cropped-8.png"
        alt="bottom"
      />,
      full: <StaticImage
        src="../../../../../../assets/images/gorod/Visualization/visual-full-8.png"
        alt="bottom"
      />,
      title: 'visualization.slide8.title',
    },
  ]
  const sum = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ]

  return (
    <>
      <section className={styles.root} id="Visualization">
        <StaticImage className={styles.bg} src="../../../../../../assets/images/gorod/bodyBg.png" alt="bg" />
        <div className={styles.title}>
          <Typography size={48} weight={700}>
            visualization.title
          </Typography>
        </div>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={0}
          loop
          autoplay={{
            delay: 0,
            disableOnInteraction: true,
          }}
          speed={200000}
          freeMode
          modules={[Autoplay, FreeMode]}
        >
          {sum.map(({ id }) => (
            <SwiperSlide key={id} className={styles.slides}>
              <div className={styles.topSlide}>
                {top.map(({ id, title, img, full }) => (
                  <div className={styles.top} key={id} onClick={() => openModal({ image: full })}>
                    {img}
                    <Typography color={'white'} size={16} weight={400}>
                      visualization.rendering
                    </Typography>
                    <Typography color={'white'} size={24} weight={700}>
                      {title}
                    </Typography>
                  </div>
                ))}
              </div>
              <div className={styles.bottomSlide}>
                {bottom.map(({ id, title, img, full }) => (
                  <div className={styles.bottom} key={id} onClick={() => openModal({ image: full })}>
                    {img}
                    <Typography color={'white'} size={16} weight={400}>
                      visualization.rendering
                    </Typography>
                    <Typography color={'white'} size={24} weight={700}>
                      {title}
                    </Typography>
                  </div>
                ))}
              </div>
            </SwiperSlide>

          ))}
        </Swiper>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className={styles.modal}
        >
          {selectedImage}
        </Modal>
      </section>
    </>
  )
}
