import React from 'react'

import PlayIcon from '../../../../../assets/images/weter/playIcon.inline.svg'

import { Hexagon, Typography } from '@UIKit'

import * as styles from './VideoPresentation.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

interface VideoPresentationProps {
  openPresentationModal: () => void
  bg: React.ReactNode
}
const background = (
  <StaticImage
    className={styles.image}
    src="../../../../../assets/images/weter/videoPresentationBackground.jpg"
    alt="presentation thumbnail"
    placeholder="blurred"
    quality={90}
  />
)

export const VideoPresentation: React.FC<VideoPresentationProps> = ({ openPresentationModal, bg = background }) => {
  return (
    <section className={styles.root} id="VideoPresentation">
      {bg}
      <Typography className={styles.title} size={64}>
        landing.video.presentation.title
      </Typography>

      <div className={styles.wrapper} onClick={openPresentationModal}>
        <Hexagon className={styles.hexagon} border cursor="pointer">
          <div className={styles.button}>
            <PlayIcon className={styles.play} />
            <Typography className={styles.text} size={12}>
              landing.video.presentation.hint
            </Typography>
          </div>
        </Hexagon>
      </div>
    </section>
  )
}
