// extracted by mini-css-extract-plugin
export var VideoPresentationBg = "Root-module--VideoPresentationBg--2088c";
export var video = "Root-module--video--169b7";
export var videoMap = "Root-module--videoMap--c5616";
export var news = "Root-module--news--d7248";
export var text = "Root-module--text--e6a98";
export var title = "Root-module--title--aff3e";
export var content = "Root-module--content--b09cf";
export var form = "Root-module--form--c07c3";
export var wrapper = "Root-module--wrapper--3d8ae";
export var subtitle = "Root-module--subtitle--64ff4";
export var inputs = "Root-module--inputs--f28ab";
export var links = "Root-module--links--e80ee";
export var item = "Root-module--item--1281f";
export var icon = "Root-module--icon--b3de6";
export var social = "Root-module--social--ea7d3";