import React from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import LikesIcon from '../../../../../../../assets/images/weter/likesIcon.inline.svg'
import ViewsIcon from '../../../../../../../assets/images/weter/viewsIcon.inline.svg'
import ArrowIcon from '../../../../../../../assets/images/weter/arrowIcon.inline.svg'

import * as styles from './LatestNewsCard.module.scss'

import { Typography } from '@UIKit'
import { useWeterNews } from '@graphql'
import { getContent } from '@utils'

interface LatestNewsCardProps {
  selectedNews: number
  news?: () => void
  openNewsModal: () => void
}

export const LatestNewsCard: React.FC<LatestNewsCardProps> = ({ news = useWeterNews, selectedNews, openNewsModal }) => {
  const { mainImage, title, shortDescription, viewsCount, likesCount } = news[selectedNews]
  return (
    <div className={styles.root}>
      <div className={styles.cover} onClick={openNewsModal}>
        <div className={styles.bar}>
          <Typography disableLocalize size={24} weight={700}>The latest news</Typography>
        </div>
        {mainImage ? (
          <GatsbyImage image={mainImage} alt="" />
        ) : (
          <StaticImage
            src="../../../../../assets/images/weter/newsPoster.jpg"
            alt="WETER LOGO"
            placeholder="blurred"
            aspectRatio={1.77} // 16 / 9
          />
        )}
      </div>
      <div className={styles.text} onClick={openNewsModal}>
        <Typography color={'white'} className={styles.title} size={24} weight={700} disableLocalize>
          {getContent(title)}
        </Typography>
        <Typography color={'white'} className={styles.description} size={18} weight={400} disableLocalize>
          {getContent(shortDescription)}
        </Typography>
        <div className={styles.info}>
          <div className={styles.indicators}>
            <ViewsIcon className={styles.icon} />
            <Typography color={'white'} className={styles.count} size={16} disableLocalize>
              {viewsCount}
            </Typography>
          </div>
          <div className={styles.indicator}>
            <LikesIcon className={styles.icon} />
            <Typography color={'white'} className={styles.count} size={16} disableLocalize>
              {likesCount}
            </Typography>
          </div>
          <div className={styles.button} onClick={openNewsModal}>
            <Typography color={'white'} className={styles.p} size={16}>landing.news.read</Typography>
            <ArrowIcon className={styles.icon} width={16} height={16} />
          </div>
        </div>
      </div>
    </div>
  )
}
