/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import cn from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'

import { Hexagon, Badge, EmbedVideo, Typography } from '@UIKit'

import Live from '../../../../../../assets/images/weter/live.inline.svg'
import Signal from '../../../../../../assets/images/weter/signal.inline.svg'
import Play from '../../../../../../assets/images/weter/playIcon.inline.svg'
import Clock from '../../../../../../assets/images/voda/clock.inline.svg'

import * as styles from './Stream.module.scss'

dayjs.extend(relativeTime)

const previews = {
  officeDubai: (
    <StaticImage
      placeholder="blurred"
      src={'../../../../../../assets/images/weter/streams/officeDubai.jpg'}
      alt="live"
      className={styles.preview}
    />
  ),
  streamVladivostok: (
    <StaticImage
      placeholder="blurred"
      src={'../../../../../../assets/images/weter/streams/streamVladivostok.jpg'}
      alt="live"
      className={styles.preview}
    />
  ),
  streamNSKRoof: (
    <StaticImage
      placeholder="blurred"
      src={'../../../../../../assets/images/weter/streams/streamNSKRoof.jpg'}
      alt="live"
      className={styles.preview}
    />
  ),
}

interface StreamProps {
  active: boolean
  live?: boolean
  id: number
  url: string
  name: string
  preview: string
  description: string
  address: string
  timezone: number
  time?: string
  style?: React.CSSProperties
  onClick: (id: number) => void
  technical: boolean
}

export const Stream: React.FC<StreamProps> = ({
  active,
  live,
  id,
  url,
  name,
  preview,
  description,
  timezone,
  address,
  time,
  style,
  onClick,
  technical,
}) => {
  const handleClick = () => {
    if (!technical) {
      onClick(id)
    }
  }

  const hide = (
    <div className={styles.technical}>
      <div className={styles.text}>
        <Clock />
        <Typography color={'white'} size={16} weight={700}>landing.in.development.title</Typography>
        <Typography color={'white'} size={12} weight={400}>landing.in.development.stream</Typography>
      </div>
      <div className={styles.bg}>{previews[preview as keyof typeof previews]}</div>
    </div>
  )

  return (
    <div className={cn(styles.root, active ? styles.main : styles.secondary, { [styles.disabled]: technical })} onClick={handleClick}>
      <div className={styles.view}>
        {active ? (
          <>
            <EmbedVideo className={styles.preview} src={url} style={style} />
            {technical && (
              <>{ hide }</>
            )}
          </>
        ) : (
          <>
            {previews[preview as keyof typeof previews]}
            {technical && (
              <>{ hide }</>
            )}
          </>
        )}

        {live && (
          <Badge className={styles.status}>
            {active ? (
              <Signal className={styles.icon} />
            ) : (
              <>
                <Live className={styles.icon} />
                <Typography size={20} disableLocalize>
                  Live
                </Typography>
              </>
            )}
          </Badge>
        )}
        {active && (
          <Badge className={styles.date}>
            <Typography color={'white'} size={20} disableLocalize>
              {dayjs().utcOffset(Number(timezone)).format('DD/MM HH:mm')}
            </Typography>
          </Badge>
        )}

        <div className={cn(styles.hexagon, { [styles.disabled]: technical })}>
          <Hexagon size={96} color="transparent">
            <Play />
          </Hexagon>
        </div>
      </div>
      <div className={styles.description}>
        <Typography size={24} weight={700}>
          {name}
        </Typography>
      </div>
    </div>
  )
}
