/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

import * as styles from './Committee.module.scss'

import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import { Modal } from '@components/App/Modal'
import { Face, CommitteeSelect } from '@components/sections'
import { Wrapper } from '@components/App/Wrapper'
import { Hexagon, Link, Typography } from '@UIKit'
import { getRandomElements, priorityCountries } from '@utils'
import { CommitteeMember, useCommittee, useCommitteeLegacy } from '@graphql'

import Group from '../../../../../assets/images/weter/group.inline.svg'
import FacebookIcon from '../../../../../assets/images/weter/facebookIcon.inline.svg'
import InstagramIcon from '../../../../../assets/images/weter/instagramIcon.inline.svg'
import TelegramIcon from '../../../../../assets/images/weter/telegramIcon.inline.svg'
import TwitterIcon from '../../../../../assets/images/weter/twitterIcon.inline.svg'
import VkIcon from '../../../../../assets/images/weter/vkIcon.inline.svg'
import LinkedinIcon from '../../../../../assets/images/weter/linkedinIcon.inline.svg'
import { committeeList } from './CommitteeList'

const DISPLAYED_COMMITTEES_NUMBER = 60

const socialMediaIcons = {
  facebook: <FacebookIcon className={styles.icon} />,
  instagram: <InstagramIcon className={styles.icon} />,
  telegram: <TelegramIcon className={styles.icon} />,
  twitter: <TwitterIcon className={styles.icon} />,
  vk: <VkIcon className={styles.icon} />,
  linkedin: <LinkedinIcon className={styles.icon} />,
}

interface CommitteeProps {
  openFormModal?: () => void
}

export const Committee: React.FC<CommitteeProps> = () => {
  const committee = useCommittee()
  const committeeLegacy = useCommitteeLegacy()
  const combinedCommittee = [...committee, ...committeeLegacy]
  const [displayedCommittee, setDisplayedCommittee] = useState<CommitteeMember[]>([])

  useEffect(() => {
    const priorities = combinedCommittee.filter((el) => priorityCountries.includes(el.country))
    const others = combinedCommittee.filter((el) => !priorityCountries.includes(el.country))
    const shuffledOthers = getRandomElements(others, DISPLAYED_COMMITTEES_NUMBER - priorities.length)

    setDisplayedCommittee(getRandomElements([...priorities, ...shuffledOthers], DISPLAYED_COMMITTEES_NUMBER))
  }, [])

  const countries = Object.keys(
    combinedCommittee.reduce((acc, member) => ({ ...acc, [member.country]: null }), {}),
  ).sort()
  const [modal, setModal] = useState(false)
  const [selected, setSelected] = useState(displayedCommittee[0])

  const handleMember = (member: CommitteeMember) => {
    setSelected(member)
    setModal(true)
  }

  return (
    <>
      <section>
        <Wrapper>
          <Typography className={styles.title} size={64} weight={500}>
            landing.committee.title
          </Typography>

          <Typography className={styles.subtitle} size={24} weight={700}>
            landing.committee.description
          </Typography>
          <div className={styles.container}>
            <Link className={styles.wrapper} to="https://my.tiaglin.com/{code}/auth" allowEn target="_blank">
              <Hexagon className={styles.hexagon} cursor="pointer">
                <Group className={styles.group} />
                <Typography color={'white'}>landing.committee.button.join</Typography>
              </Hexagon>
            </Link>

            <div className={styles.scroll}>
              <div className={styles.faces}>

                {displayedCommittee.map((committeeMember, index) => {
                  const { name, country, image } = committeeMember
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className={styles.face} onClick={() => handleMember(committeeMember)}>
                      <Face name={name} image={image} country={country} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <CommitteeSelect countries={countries} committee={combinedCommittee} handleMember={handleMember} />
        </Wrapper>
      </section>

      {selected && (
        <Modal className={styles.modal} isOpen={modal} onRequestClose={() => setModal(false)}>
          <div className={styles.content}>
            <GatsbyImage className={styles.avatar} image={selected.image} alt={selected.name} />
            <div className={styles.info}>
              <div>
                <Typography disableLocalize size={32}>
                  {selected.name}
                </Typography>
                <Typography className={styles.country} missOnAlias size={20}>
                  {`country.${selected.country}`}
                </Typography>
              </div>
              <div>
                {selected.socialMedia.map(({ type, url }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Link className={styles.social} key={index} to={url} target="_blank">
                    <Hexagon size={52} color="dark" cursor="pointer">
                      {socialMediaIcons[type as keyof typeof socialMediaIcons]}
                    </Hexagon>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
