// extracted by mini-css-extract-plugin
export var root = "Schema-module--root--94c5b";
export var missMargin = "Schema-module--missMargin--a54b5";
export var container = "Schema-module--container--1b423";
export var opacity = "Schema-module--opacity--83c91";
export var levelsMobile = "Schema-module--levelsMobile--ac9b2";
export var up = "Schema-module--up--1fcd8";
export var down = "Schema-module--down--97724";
export var hint = "Schema-module--hint--f9b47";
export var cursor = "Schema-module--cursor--7dd3f";
export var cursorMobile = "Schema-module--cursorMobile--03c16";
export var text = "Schema-module--text--cdef4";
export var hintHidden = "Schema-module--hintHidden--1deff";
export var levels = "Schema-module--levels--b4a4f";
export var level = "Schema-module--level--4c1f9";
export var level1 = "Schema-module--level1--9f0f8";
export var level2 = "Schema-module--level2--44129";
export var level3 = "Schema-module--level3--31c7c";
export var level4 = "Schema-module--level4--ad52a";
export var level5 = "Schema-module--level5--9ed31";
export var top1 = "Schema-module--top1--856b3";
export var top2 = "Schema-module--top2--366b2";
export var top3 = "Schema-module--top3--b7a07";
export var top4 = "Schema-module--top4--3bb8f";
export var top5 = "Schema-module--top5--f0c63";
export var tooltip = "Schema-module--tooltip--6162a";
export var hidden = "Schema-module--hidden--097f6";
export var build = "Schema-module--build--605c4";
export var icon = "Schema-module--icon--e0df3";
export var tooltipUp = "Schema-module--tooltipUp--32502";
export var hiddenUp = "Schema-module--hiddenUp--170eb";
export var tooltipMobile = "Schema-module--tooltipMobile--d348a";
export var tooltipMobileUp = "Schema-module--tooltipMobileUp--e1f25";
export var number = "Schema-module--number--9c6c8";
export var gradient = "Schema-module--gradient--4e0ed";
export var background = "Schema-module--background--19e1f";
export var backgroundMobile = "Schema-module--backgroundMobile--85888";
export var carousel = "Schema-module--carousel--6579f";
export var navigation = "Schema-module--navigation--290ae";
export var counter = "Schema-module--counter--62903";
export var nav = "Schema-module--nav--1f17e";
export var grey = "Schema-module--grey--9c70d";
export var swiper = "Schema-module--swiper--cb7e9";
export var slide = "Schema-module--slide--41934";
export var slide1 = "Schema-module--slide1--3bf47";
export var slide2 = "Schema-module--slide2--7e27a";
export var slide3 = "Schema-module--slide3--789eb";
export var slide4 = "Schema-module--slide4--dd981";
export var slide5 = "Schema-module--slide5--c6014";
export var slide6 = "Schema-module--slide6--b2368";
export var slide7 = "Schema-module--slide7--8436c";
export var distableAnimation = "Schema-module--distableAnimation--66746";
export var description = "Schema-module--description--20e7a";
export var hiddenMobileUp = "Schema-module--hiddenMobileUp--9df37";
export var line = "Schema-module--line--33010";