import React from 'react'

import { SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import './swiper.scss'

import * as styles from './News.module.scss'

import { CardNews } from '@components/sections/News/CardNews'
import { getContent } from '@utils'
import { Carousel, Typography } from '@UIKit'
import cn from 'classnames'

interface NewsProps {
  setSelectedNews: (index: number) => void
  openNewsModal: () => void
  newsHook?: () => {
    data: any[]
  };
  disabled?: boolean;
}

export const News: React.FC<NewsProps> = ({ setSelectedNews, openNewsModal, newsHook, disabled = false }) => {
  const news = newsHook

  const handleNewsCardClick = (index: number) => {
    setSelectedNews(index)
    openNewsModal()
  }

  return (
    <section id="News">
      <Typography size={64} weight={500} className={styles.title} missOnAlias>
        weter.news.title
      </Typography>

      <div className={cn(styles.carousel, { [styles.disabled]: disabled })}>
        <Carousel
          slidesPerView={4}
          navigation
          loop={!disabled}
          centeredSlides={false}
          slides={news.map(({ id, publishedAt, mainImage, title, shortDescription, viewsCount, likesCount }, index) => (
            <SwiperSlide className={styles.disabled} key={id} onClick={() => handleNewsCardClick(index)}>
              <CardNews
                date={publishedAt}
                image={mainImage}
                title={getContent(title)}
                description={getContent(shortDescription)}
                views={viewsCount}
                likes={likesCount}
              />
            </SwiperSlide>
          ))}
        />
      </div>
    </section>
  )
}
