// extracted by mini-css-extract-plugin
export var root = "Main-module--root--6370a";
export var background = "Main-module--background--435b7";
export var infoMobile = "Main-module--infoMobile--5ff20";
export var backgroundMobile = "Main-module--backgroundMobile--c6d7f";
export var frame = "Main-module--frame--49ddc";
export var frameMobile = "Main-module--frameMobile--3935c";
export var news = "Main-module--news--602f9";
export var latestNews = "Main-module--latestNews--c6c65";
export var info = "Main-module--info--d07cb";
export var bottom = "Main-module--bottom--9cc7a";
export var hexagon = "Main-module--hexagon--8356d";
export var back = "Main-module--back--abfe3";
export var icon = "Main-module--icon--a7c06";
export var video = "Main-module--video--21ade";